.ng-doc-decorators-group {
	display: flex;
	gap: var(--ng-doc-base-gutter);
	margin-bottom: calc(var(--ng-doc-base-gutter) / 2);

	code {
		--ng-doc-line-height: 18px;
	}

	&.column {
		flex-direction: column;
		align-items: flex-start;
		gap: calc(var(--ng-doc-base-gutter) / 2);
		margin-bottom: 0;

		code {
			margin: 0;
		}
	}
}

.ng-doc-badge-wrapper {
	display: inline-flex;
	margin-right: var(--ng-doc-base-gutter);
	vertical-align: middle;

	.ng-doc-badge:first-child {
		border-top-left-radius: var(--ng-doc-base-gutter);
		border-bottom-left-radius: var(--ng-doc-base-gutter);
	}

	.ng-doc-badge:last-child {
		border-top-right-radius: var(--ng-doc-base-gutter);
		border-bottom-right-radius: var(--ng-doc-base-gutter);
	}
}

.ng-doc-badge {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: calc(var(--ng-doc-base-gutter) * 2);
	height: calc(var(--ng-doc-base-gutter) * 2);
	font-family: var(--ng-doc-heading-font-family);
	font-size: 10px;
	color: var(--ng-doc-white);
	white-space: nowrap;
	font-weight: 900;
	background-color: var(--ng-doc-text);
	text-transform: capitalize;

	&[data-content='protected'] {
		background-color: var(--ng-doc-protected-background);
		color: var(--ng-doc-protected-color);
	}

	&[data-content='abstract'] {
		background-color: var(--ng-doc-abstract-background);
		color: var(--ng-doc-abstract-color);
	}

	&[data-content='static'] {
		background-color: var(--ng-doc-static-background);
		color: var(--ng-doc-static-color);
	}

	&[data-content='async'] {
		background-color: var(--ng-doc-async-background);
		color: var(--ng-doc-async-color);
	}

	&[data-content='overriden'] {
		background-color: var(--ng-doc-overriden-background);
		color: var(--ng-doc-overriden-color);
	}

	&[data-content='readonly'] {
		background-color: var(--ng-doc-readonly-background);
		color: var(--ng-doc-readonly-color);
	}
}

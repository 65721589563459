@import '../mixins/entities-colors';
@import '../../../ui-kit/styles/mixins';

.ng-doc-tags-wrapper {
  line-height: 22px;
}

.ng-doc-tag {
  display: inline-block;
  padding: 0 var(--ng-doc-base-gutter);
  font-size: 14px;
  border-radius: var(--ng-doc-base-gutter);
  background-color: color-mix(
    in srgb,
    var(--ng-doc-css-tag-background) var(--ng-doc-css-tag-background-opacity, 15%),
    transparent
  );
  vertical-align: middle;
  white-space: nowrap;
  color: var(--ng-doc-css-tag-background);

  & + .ng-doc-tag {
    margin-left: var(--ng-doc-base-gutter);
  }

  &[data-content='ng-doc-scope'] {
    padding: 0;
    background-color: transparent;
    color: var(--ng-doc-text);
  }

  @include entitiesColors('data-content', '--ng-doc-css-tag-background', null);
}

.ng-doc-deprecated {
  text-decoration: line-through;

  &:hover {
    text-decoration: auto;
  }
}

.ng-doc-api-status {
  // inline-block and width 100% to prevent the status from text decoration
  display: inline-block;
  width: 100%;

  & > *:first-child {
    margin-top: 0 !important;
  }

  &:empty {
    display: none;
  }
}

.ng-doc-deprecated-notes {
  color: var(--ng-doc-warning-text);
}

.ng-doc-returns {
  display: flex;
  align-items: start;
  gap: calc(var(--ng-doc-base-gutter) * 1.5);
  margin: var(--ng-doc-page-block-margin);

  p {
    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@import '../mixins/headings';
@import '../defaults';

$class: $ng-doc-element;

article {
	header#{$class} {
		.ng-doc-page-tags {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-bottom: calc(var(--ng-doc-base-gutter) * 2);
			line-height: 22px;
		}

		h1 {
			margin-right: var(--ng-doc-base-gutter);
		}

		.ng-doc-header-details {
			display: inline-flex;
			flex-wrap: wrap;
			align-items: baseline;
			gap: var(--ng-doc-base-gutter);
			margin-top: var(--ng-doc-base-gutter);
			line-height: 18px;

			code {
				--ng-doc-line-height: 18px;
			}

			.ng-doc-header-details-label {
				font-size: 11px;
				font-weight: 600;
				line-height: 18px;
				margin-left: calc(var(--ng-doc-base-gutter) * 2);
				opacity: 0.5;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
}

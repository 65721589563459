@import 'mixins';

:root {
  --ng-doc-base-gutter: 8px;
  --ng-doc-transition: 0.2s;
  --ng-doc-text-selection: rgba(21, 88, 214, 0.15);

  --ng-doc-white: #fff;
  --ng-doc-black: #000;
  --ng-doc-base-rgb: 0 0 0;
  --ng-doc-base-0: var(--ng-doc-white);
  --ng-doc-base-1: #f6f6f6;
  --ng-doc-base-2: #ebebeb;
  --ng-doc-base-3: #dedede;
  --ng-doc-base-4: #d1d1d1;
  --ng-doc-base-5: #b8b8b8;
  --ng-doc-base-6: #ababab;
  --ng-doc-base-7: #9e9e9e;
  --ng-doc-base-8: #919191;
  --ng-doc-base-9: #858585;
  --ng-doc-base-10: #6e6e6e;

  // Main colors
  --ng-doc-primary: #1558d6;
  --ng-doc-primary-text: var(--ng-doc-white);

  --ng-doc-alert: #ff5959;
  --ng-doc-alert-text: var(--ng-doc-white);

  --ng-doc-warning: #ff9f1a;
  --ng-doc-warning-text: var(--ng-doc-white);

  --ng-doc-info: #3f68b5;
  --ng-doc-info-text: var(--ng-doc-white);

  --ng-doc-success: #00b74a;
  --ng-doc-success-text: var(--ng-doc-white);

  --ng-doc-background: var(--ng-doc-base-0);
  --ng-doc-hover-background: rgba(110, 110, 110, 0.09);
  --ng-doc-border-size: 1px;
  --ng-doc-border-color: var(--ng-doc-base-3);
  --ng-doc-border-hover-color: var(--ng-doc-base-5);
  --ng-doc-shadow-color: rgba(0, 0, 0, 0.15);

  // Font
  --ng-doc-font-system: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  --ng-doc-font-system-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
  --ng-doc-heading-font-family: var(--ng-doc-font-system);
  --ng-doc-heading-color: #111111;
  --ng-doc-font-family: var(--ng-doc-font-system);
  --ng-doc-font-size: calc(var(--ng-doc-base-gutter) * 2);
  --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 3);
  --ng-doc-font-weight: 400;
  --ng-doc-code-font: var(--ng-doc-font-system-mono);
  --ng-doc-code-font-size: 14px;
  --ng-doc-code-line-height: 20px;
  --ng-doc-code-font-weight: 400;
  --ng-doc-text: rgb(78 78 78);
  --ng-doc-text-muted: var(--ng-doc-base-8);

  // Mark
  --ng-doc-mark-background: rgba(255, 222, 0, 0.3);

  // Link
  --ng-doc-link-color: var(--ng-doc-primary);

  // Inline code
  --ng-doc-inline-code-background: var(--ng-doc-base-9);
  --ng-doc-inline-code-color: var(--ng-doc-text);

  // NgDocButtonIconComponent
  --ng-doc-button-background: trasparent;
  --ng-doc-button-background-opacity: 0;
  --ng-doc-button-color: var(--ng-doc-text);
  --ng-doc-button-hover-background: var(--ng-doc-base-2);
  --ng-doc-button-hover-color: var(--ng-doc-button-color);
  --ng-doc-button-active-background: var(--ng-doc-base-3);
  --ng-doc-button-active-color: var(--ng-doc-button-hover-color);

  // NgDocFloatedBorderComponent
  --ng-doc-floated-border-color: transparent;
  --ng-doc-floated-border: 2px solid var(--ng-doc-floated-border-color);
  --ng-doc-floated-border-shadow-color: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px transparent;
  --ng-doc-floated-border-radius: var(--ng-doc-base-gutter);

  /* NgDocCheckboxComponent */
  --ng-doc-checkbox-color: var(--ng-doc-primary);
  --ng-doc-checkbox-color-text: var(--ng-doc-primary-text);
  --ng-doc-checkbox-border: var(--ng-doc-border-size) solid var(--ng-doc-border-color);
  --ng-doc-checkbox-border-hover: var(--ng-doc-border-size) solid var(--ng-doc-border-hover-color);

  /* NgDocInputWrapperComponent */
  --ng-doc-input-text-align: left;
  --ng-doc-input-width: 100%;
  --ng-doc-input-height: 40px;
  --ng-doc-input-border: var(--ng-doc-border-size) solid var(--ng-doc-border-color);
  --ng-doc-input-border-hover: var(--ng-doc-border-size) solid var(--ng-doc-border-hover-color);
  --ng-doc-input-background-color: var(--ng-doc-base-0);
  --ng-doc-input-cursor: auto;

  /* NgDocTagComponent */
  --ng-doc-tag-background: var(--ng-doc-primary);
  --ng-doc-tag-color: var(--ng-doc-primary-text);

  /* NgDocSelection */
  --ng-doc-selection-border: 2px solid var(--ng-doc-primary);
  --ng-doc-selection-background: transparent;

  /* NgDocDot */
  --ng-doc-dot-size: calc(var(--ng-doc-base-gutter) / 2);
  --ng-doc-dot-background: var(--ng-doc-primary);

  /* Sidenav */
  --ng-doc-sidenav-width: 300px;

  /* TabGroup */
  --ng-doc-tab-group-border-radius: var(--ng-doc-base-gutter);
  --ng-doc-tab-group-border: 1px solid var(--ng-doc-border-color);
}

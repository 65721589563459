@import '../../styles/mixins';

.ng-doc-notify {
	--ng-doc-overlay-background: var(--ng-doc-base-10);
	--ng-doc-overlay-border: var(--ng-doc-base-10);
	--ng-doc-overlay-border-radius: calc(var(--ng-doc-base-gutter) / 2);

	.ng-doc-overlay-content {
		@include ng-doc-font();
		padding: var(--ng-doc-base-gutter) calc(var(--ng-doc-base-gutter) * 2);
		max-width: 576px;
		max-height: 50vh;
		white-space: pre-line;
		overflow-y: auto;

		--ng-doc-text: var(--ng-doc-base-0);
	}
}

@import '../../../ui-kit/styles/mixins';

.ng-doc-modifiers-wrapper {
	line-height: 18px;
}

.ng-doc-modifier {
	font-size: 14px;
	color: var(--ng-doc-text);
	font-family: var(--ng-doc-heading-font-family);
	white-space: nowrap;
	font-weight: 800;

	&[data-content='protected'] {
		color: var(--ng-doc-protected-background);
	}

	&[data-content='abstract'] {
		color: var(--ng-doc-abstract-background);
	}

	&[data-content='static'] {
		color: var(--ng-doc-static-background);
	}

	&[data-content='async'] {
		color: var(--ng-doc-async-background);
	}

	&[data-content='overriden'] {
		color: var(--ng-doc-overriden-background);
	}

	&[data-content='readonly'] {
		color: var(--ng-doc-readonly-background);
	}

	&[data-content='get'] {
		font-size: 16px;
		color: var(--ng-doc-get-background);
	}

	&[data-content='set'] {
		font-size: 16px;
		color: var(--ng-doc-set-background);
	}
}

.ng-doc-node-details {
	@include ng-doc-font();
	--ng-doc-code-line-height: var(--ng-doc-line-height);

	--ng-doc-font-size: 14px;
	--ng-doc-text: var(--ng-doc-text-muted);
	--ng-doc-font-weight: 400;
	--ng-doc-line-height: 22px;

	code.ng-doc-code-with-link {
		--ng-doc-inline-code-background: transparent !important;
		padding: 0;

		--ng-doc-code-font-size: var(--ng-doc-font-size);
	}
}

@import 'main';

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

html,
body {
  @include ng-doc-font();
  font-size: var(--ng-doc-font-size);
  background-color: var(--ng-doc-background);
  margin: 0;
  height: 100%;
}

.ng-doc-blur-backdrop {
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.56);
}

.ng-doc-global-scrollblock {
  position: relative;
  overflow: hidden;
  padding-right: var(--ng-doc-base-gutter);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::selection {
  background: var(--ng-doc-text-selection);
}
